<template>
  <section id="add-noticia">
    <div class="row">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mt-3 mb-5">
            <i class="fa-solid fa-newspaper color-secondary"></i>
            Completa los siguientes pasos para subir una noticia
        </h3>
        <div class="card card-custom border-round-10 card-shadow border-0">
          <div class="card-body d-flex flex-column mh-700">
            <div class="form-step">
                <div 
                  @click="clickSteps(1)" 
                  class="form-step-item"
                  :class="handleStepsClass(1)">
                  1
                </div>
                <div 
                  @click="clickSteps(3)" 
                  class="form-step-item" 
                  :class="handleStepsClass(3)">
                  2
                </div>
                <div 
                  @click="clickSteps(4)" 
                  class="form-step-item" 
                  :class="handleStepsClass(4)">
                  3
                </div>
            </div>

            <div 
              v-if="showSection('title')"
              class="row flex-grow-1">
              <div class="col-12">
                <div class="row mt-3">
                  <div class="col-12 col-lg-6">
                    <div class="row">
                      <div class="col-12">
                        <label class="text-secondary">Añade un título</label>
                        <input 
                          type="text" 
                          class="form-control input-custom" 
                          maxlength="75" 
                          v-model="news_title" 
                          ref="newsTitle"
                        />
                        <div class="form-text text-end">{{ news_title.length }} / 75</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="text-secondary">Selecciona una categoría</label>
                        <v-select
                          class="selvue-custom mb-3"
                          :options="categorias"
                          v-model="cat_selected"
                          label="nombre_categoria"
                          placeholder="Seleccionar"
                          :clearable="false"
                          >
                        </v-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <label class="text-secondary">
                      <i class="fa-regular fa-image color-secondary"></i>
                      Imagen principal de la noticia
                      <a 
                        class="link-secondary"
                        content="Se visualizará en el carrusel del <strong>home</strong><br>  y en la sección de <strong>noticias</strong>." 
                        v-tippy="{ arrow: true }"
                        href="javascript:">
                        <i class="fa-regular fa-circle-question link-cursor"></i>
                      </a>
                    </label>
                    <UploadImg @sendImg="handleMainImg" v-if="news_main_img.base64 == ''"/>
                    <div 
                      v-if="news_main_img.base64 !== ''"
                      class="upload-image"
                    >
                      <div class="upload-image-preview">
                        <button 
                          @click="deletePreview('main')"
                            class="upload-image-preview-remove"   
                        >
                            <font-awesome-icon icon="times"/>
                        </button>
                        <img 
                            class="upload-image-preview-img"
                            :src="news_main_img.base64"/>
                      </div>
                    </div>
                    <div class="alert alert-light border text-center p-2 mt-2">
                      <small>
                      <i class="fa-solid fa-circle-info"></i>
                      Peso máximo: 2MB, Formatos: PNG/JPG
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div 
              v-if="showSection('content')"
              class="row flex-grow-1">
              <div class="col-12">
                <div class="row mt-3">
                  <div class="col-12 col-lg-3 d-flex flex-column mt-3 mt-lg-0">
                    <h5 class="font-main text-secondary">Selecciona elemento</h5>
                    <div class="card flex-grow-1">
                      <div class="card-body">
                        <h6 class="font-main-bold color-main text-center">Elementos</h6>
                        <div class="list-group">
                          <a 
                            v-for="elemento in elementos"
                            :key="elemento.id"
                            href="javascript:" 
                            @click="show_component = elemento.component"
                            class="list-group-item list-group-item-action border border-2 rounded-pill mb-2"
                            :class="elemento.component === show_component ? 'link-primary border-primary' : ' text-secondary'"
                          >
                            <i :class="elemento.icon"></i>
                            {{ elemento.label }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6 d-flex flex-column mt-3 mt-lg-0">
                    <h5 class="font-main text-secondary mt-3 mt-lg-0">Añade contenido</h5> 
                    <div class="card flex-grow-1">
                      <div class="card-body d-flex flex-column flex-grow-1">
                        <h6 class="font-main-bold color-main text-center">Contenido interior noticia</h6>

                        <!--COMPONENT TEXTO-->
                        <div 
                          v-if="show_component == 'Texto'"
                          id="noticias-component-texto" 
                          class="flex-grow-1 mb-3"
                        >
                          <div class="row flex-grow-1">
                            <div class="col-12">
                              <editor-content 
                                class="tiptap" 
                                :key="editor_key" 
                                :editor="editor" 
                                v-model="news_content"
                                ref="newsContent" 
                              />
                            </div>
                          </div>
                          <div class="d-flex align-items-center justify-content-between mt-2">
                            <div class="btn-group btn-wysiwyg">
                              <button 
                                class="btn btn-outline-secondary"
                                @click="editor.chain().focus().toggleBold().run()"
                                :class="{ 'active': editor.isActive('bold') }"
                              >
                                <font-awesome-icon icon="bold"/>
                              </button>
                              <button 
                                class="btn btn-outline-secondary"
                                @click="editor.chain().focus().toggleItalic().run()"
                                :class="{ 'active': editor.isActive('italic') }"
                              >
                                <font-awesome-icon icon="italic"/>
                              </button>
                              <button 
                                class="btn btn-outline-secondary"
                                @click="editor.chain().focus().toggleUnderline().run()"
                                :class="{ 'active': editor.isActive('underline') }"
                              >
                                <font-awesome-icon icon="underline"/>
                              </button>
                              <button 
                                class="btn btn-outline-secondary"
                                @click="editor.chain().focus().toggleBulletList().run()"
                                :class="{ 'active': editor.isActive('bulletList') }"
                              >
                                <font-awesome-icon class="icono" icon="list"/>
                              </button>
                              <button 
                                class="btn btn-outline-secondary"
                                @click="editor.chain().focus().toggleOrderedList().run()"
                                :class="{ 'active': editor.isActive('orderedList') }"
                              >
                                <font-awesome-icon class="icono" icon="list-ol"/>
                              </button>
                              <button 
                                class="btn btn-outline-secondary"
                                v-if="!editor.isActive('link')"
                                @click="setLink" 
                                content="Esta opción te permitirá insertar un enlace a una página web"
                                v-tippy="{ arrow: true }" 
                                :class="{ 'active': editor.isActive('link') }"
                              >
                                <font-awesome-icon class="icono" icon="link"/>
                              </button>
                              <button 
                                class="btn btn-outline-secondary"
                                v-if="editor.isActive('link')"
                                @click="editor.chain().focus().unsetLink().run()"
                                :class="{ 'active': editor.isActive('link') }" 
                              >
                                <font-awesome-icon class="icono" icon="link"/>
                              </button>
                            </div>                    
                            <div class="form-text">
                              {{ editor.storage.characterCount.characters() }} / {{ editor_limit }}
                            </div>
                          </div>
                        </div>

                        <!--COMPONENT IMAGEN-->
                        <div 
                          v-if="show_component == 'Imagen'"
                          id="noticias-component-imagen" 
                          class="flex-grow-1 mt-3"
                        >
                          <div class="row mb-3">
                            <div class="col-12">
                              <div class="upload-image">
                                <label
                                  v-if="component.img == '' "
                                  class="upload-image-label" 
                                  for="input-image">
                                  <span class="upload-image-label-icon">
                                    <i class="fa-solid fa-arrow-up-from-bracket color-secondary"></i>
                                  </span>
                                  <input 
                                    type="file" 
                                    id="input-image" 
                                    @change="loadFiles($event)" 
                                    accept=".png , .jpg, .jpeg"
                                    style="display: none"
                                  />
                                  <span class="upload-image-label-text">
                                    Haz clic aquí<br> 
                                    <small>para seleccionar una imagen</small>
                                  </span>
                                </label>
                                <div 
                                  class="upload-image-preview" 
                                  v-if="component.img !== '' "
                                >
                                  <button 
                                    @click="(component.foto = ''), (component.img = ''), (component.url = '')"
                                    class="upload-image-preview-remove"   
                                  >
                                    <font-awesome-icon icon="times"/>
                                  </button>
                                  <img 
                                    class="upload-image-preview-img"
                                    :src="component.img"
                                  />
                                </div>
                              </div>
                              <div class="alert alert-light border text-center p-2 mt-2">
                                <small>
                                <i class="fa-solid fa-circle-info"></i>
                                Peso máximo: 2MB, Formatos: PNG/JPG
                                </small>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="card">
                                <div class="card-body">
                                  <div class="form-check form-switch form-switch-custom">
                                    <input 
                                      class="form-check-input"
                                      type="checkbox" 
                                      id="switch-ida-vuelta"
                                      v-model="hipervinculo_switch" 
                                    >
                                    <label
                                      class="form-check-label link-cursor" 
                                      for="switch-ida-vuelta"
                                    >
                                      Añadir hipervínculo a la imagen
                                    </label>
                                  </div>
                                  <div class="mt-3" v-if="hipervinculo_switch">
                                    <input 
                                      class="form-control input-custom"
                                      placeholder="https://example.com"
                                      v-model="component.url" 
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--COMPOENT VIDEO-->
                        <div 
                          v-if="show_component == 'Video'"
                          id="noticias-component-video"
                          class="flex-grow-1 mt-3"
                        >
                          <div class="upload-image">
                            <label
                              v-if="component.video_prev == ''"
                              class="upload-image-label"
                              for="input-video">
                              <span class="upload-image-label-icon">
                                <i class="fa-solid fa-arrow-up-from-bracket color-secondary"></i>
                              </span>
                              <input 
                                type="file" 
                                id="input-video"
                                @change="loadFiles($event)" 
                                accept=".mp4 , .avi, .mov"
                                style="display: none"
                              />
                              <span class="upload-image-label-text">
                                Haz clic aquí<br> 
                                <small>para seleccionar un video</small>
                              </span>
                            </label>
                            <div 
                              class="upload-image-preview" 
                              v-if="component.video_prev !== ''"
                            >
                              <button 
                                @click="(component.video = ''), (component.video_prev = '')"
                                class="upload-image-preview-remove"   
                              >
                                <font-awesome-icon icon="times"/>
                              </button>
                              <video 
                                controls 
                                :src="component.video_prev" 
                                class="ratio ratio-16x9"
                                alt="Video">
                              </video>
                            </div>
                          </div>
                          <div class="alert alert-light border text-center p-2 mt-2">
                            <small>
                            <i class="fa-solid fa-circle-info"></i>
                            Peso máximo: 30MB, Formatos: MP4/AVI/MOV
                            </small>
                          </div>

                          <div class="url" v-if="video_switch == 'switchright'">
                            <p>Link de video:</p>
                            <div class="url-input">
                              <input v-model.trim="component.videourl" placeholder="https//: . . ." type="text" />
                            </div>
                          </div>
                        </div>

                        <!--COMPONENT DOCUMENTO-->
                        <div                  
                          v-if="show_component == 'Documento'"
                          id="noticias-component-documento"
                          class="flex-grow-1 mt-3"
                        >
                          <div class="row mb-3">
                            <div class="col-12">
                              <label>Nombre del documento</label>
                              <input 
                                type="text"
                                class="form-control input-custom"
                                v-model="component.pdf_name"
                              />
                            </div>
                          </div>
                          <div class="upload-image">
                            <label
                              v-if="component.pdf_prev == ''"
                              class="upload-image-label upload-image-label-document"
                              for="input-file">
                              <span class="upload-image-label-icon">
                              </span>
                              <input 
                                type="file" 
                                id="input-file" 
                                @change="loadFiles($event)" 
                                accept=".pdf"
                                style="display: none" 
                              />
                              <span class="upload-image-label-text">
                                <i class="fa-solid fa-arrow-up-from-bracket color-secondary"></i>
                                Haz clic aquí para seleccionar un documento
                              </span>
                            </label>
                            <div 
                              class="upload-image-preview upload-image-preview-document" 
                              v-if="component.pdf_prev !== ''"
                            >
                              <button 
                                @click="(component.pdf = ''), (component.pdf_prev = '')"
                                class="upload-image-preview-remove"   
                              >
                                <font-awesome-icon icon="times"/>
                              </button>
                              {{
                                component.docname.length == 0
                                ? `Documento sin nombre`
                                : `${component.docname.substring(0, 30)}${
                                30 > component.docname.length ? "" : "..."
                                }`
                                }}
                                {{
                                component.size == 0
                                ? ``
                                : `${((component.size * 10) / 10).toFixed(2)} MB`
                              }}
                            </div>
                          </div>
                          <div class="alert alert-light border text-center p-2 mt-2">
                            <small>
                              <i class="fa-solid fa-circle-info"></i>
                              Peso máximo: 10MB, Formato: PDF
                            </small>
                          </div>
                        </div>

                        <!--COMPONENT AUTOR-->
                        <div                  
                          v-if="show_component == 'Usuario'"
                          id="noticias-component-autor"
                          class="flex-grow-1 my-3"
                        >
                          <div class="row">
                            <div class="col-12 input-search">
                              <input 
                                type="text" 
                                class="form-control input-custom"
                                placeholder="Nombre Usuario"
                                @keydown.enter="getUsers" 
                                v-model="user_name"
                              />
                                <span class="input-search-icon">
                                  <font-awesome-icon icon="search"/>
                                </span>
                            </div>
                          </div>
                          <div 
                            v-if="user_list.length !== 0"
                            class="row mt-3">
                            <div class="col-12">
                              <div class="list-group list-group-flush border scroll-custom-350 rounded">
                                <a 
                                  href="#" 
                                  v-for="(us, i) in user_list" 
                                  :key="i" 
                                  @click="(user = us), (user_name = `${user.primer_nombre} ${user.apellido_paterno}`)" 
                                  class="list-group-item list-group-item-action d-flex align-item-center justify-content-between text-secondary"
                                  :class="user == us ? 'bg-info bg-opacity-25' : ''"
                                >
                                  <span>
                                    <font-awesome-icon icon="circle-user" class="color-secondary"/>
                                    {{ us.primer_nombre | capitalize }} 
                                    {{ us.apellido_paterno | capitalize }}
                                  </span>
                                  <span class="badge border text-secondary">
                                    {{ `${us.rut_usuario}-${us.dv_usuario}` }}
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12">
                            <div class="d-flex align-items-center justify-content-end">
                              <button 
                                v-if="edit_texto && step == 'content'" 
                                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                                @click="cancelEdit()"
                              >
                                Cancelar
                              </button>
                              <button 
                                v-if="!edit_texto && step == 'content'" 
                                class="btn btn-custom-color-blue border-round-50 mw-100" 
                                :disabled="validContent"
                                @click="push()"
                              >
                                Añadir
                              </button>
                              <button 
                                v-if="edit_texto && step == 'content'" 
                                class="btn btn-custom-color-blue border-round-50 mw-100" 
                                @click="pushEdited()"
                              >
                                Aceptar
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 d-flex flex-column mt-3 mt-lg-0">
                    <h5 class="font-main text-secondary mt-3 mt-lg-0">Edita y reorganiza</h5>
                    <div class="card flex-grow-1 scroll-custom-550">
                      <div class="card-body">         
                        <h6 class="font-main-bold color-main text-center">Elementos Añadidos</h6>

                        <Draggable 
                          :animation="200" 
                          :list="comtent_objets" 
                          class="list-group" 
                          ghost-class="ghost" 
                          @end="onDrop">
                          <div 
                            v-for="(co, i) in comtent_objets" 
                            :key="i"
                          >
                            <div
                              v-if="co.contenido !== null"
                              class="list-group-item d-flex justify-content-between align-items-center border text-secondary rounded-pill mb-2 link-custom link-cursor"
                              :class="edit_selected_texto.title == co.title ? 'border-primary bg-info bg-opacity-10' : '' "
                              >
                              <span class="fs-5">
                                <i class="fa-solid fa-grip-vertical color-light"></i>
                                <span class="color-main ms-1">{{ co.title.split(";")[1] }}</span>
                                <span v-if="co.title.split(';')[0] === 'Reportero'"> Autor</span>
                                <span v-else> {{ co.title.split(";")[0] }}</span>
                              </span>
                              <span class="link-custom-hover">
                                <a 
                                  v-if="co.contenido != null && !edit_texto" 
                                  href="javascript:"
                                  class="me-2"
                                >
                                  <font-awesome-icon 
                                    content="Edita este elemento" 
                                    v-tippy="{ arrow: true }" 
                                    icon="pen"
                                    class="color-brown"
                                    @click="editText(co, i)"
                                  />
                                </a>
                                <a
                                  v-if="co.contenido != null && !edit_texto" 
                                  href="javascript:"
                                  class="me-2"
                                >
                                  <font-awesome-icon 
                                    content="Elimina este elemento" 
                                    v-tippy="{ arrow: true }" 
                                    icon="trash-alt"
                                    class="color-red"
                                    @click="deleteComponent(co, i)"
                                  />
                                </a>
                              </span>
                            </div>
                          </div>
                        </Draggable>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--PREVIEW-->
            <div 
              v-if="showSection('preview')"
              class="row flex-grow-1">
              <div class="col-12 mt-3">
                <h5 class="font-main text-secondary">{{ step_title }}</h5>
                <div class="row">
                  <div class="col-12 col-lg-7">
                    <div class="card card-custom border-round-10 card-shadow border-0 new-preview">
                      <div class="card-body">
                        <figure class="figure new-preview-figure w-100">
                          <img 
                          :src="news_main_img.base64" 
                          class="figure-img img-fluid w-100 rounded"
                          >
                          <div class="new-preview-figure-date">
                            <div 
                              v-for="(dt, i) in dateNewFormat(news_date, 1).split(';')" 
                              :key="i"
                              :class="`new-preview-figure-date-${i == 0 ? 'a' : 'b'}`"
                            >
                              {{ dt }}
                            </div>
                          </div>
                          <span class="new-preview-figure-title">{{ news_title }}</span>
                          <span class="new-preview-figure-triangle"></span>
                        </figure>
                        <div 
                          v-for="(el, i) in comtent_objets" 
                          :key="i" 
                          :class="`new-preview-${el.tipo}`"
                          class="row">
                          <div class="col-12" v-html="loadPreview(el)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-5">

                    <div 
                      v-if="!edit_noticia"
                      class="card"
                    >
                      <div class="card-body">
                        <div class="form-check form-switch form-switch-custom">
                          <input 
                            class="form-check-input"
                            type="checkbox" 
                            id="switch-programar-noticia"
                            v-model="calendar_switch"
                          >
                          <label
                            class="form-check-label link-cursor" 
                            for="switch-programar-noticia"
                          >
                            ¿Deseas programar esta noticia?
                          </label>
                        </div>
                        <div 
                          v-if="calendar_switch && calendar_mod"
                          class="mt-3" 
                          >
                          <v-date-picker 
                            color="yellow" 
                            class="w-100" 
                            :key="key_calendar"
                            :min-date="min_date" 
                            @dayclick="onDayClick" 
                            v-model="calendar"
                          />

                          <div 
                            class="mt-3">
                            <label>Hora de publicación</label>
                            <input 
                              type="text" 
                              class="form-control input-custom"
                              v-mask="maskDateTime" 
                              v-model="dateTime" 
                              placeholder="hh:mm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="alert alert-info text-center"  v-else>
                      {{ calendar_msg }}
                    </div>

                    <div class="card mt-3">
                      <div class="card-body">
                        <div class="form-check form-switch form-switch-custom">
                          <input 
                            class="form-check-input"
                            type="checkbox" 
                            id="switch-alerta-noticia"
                            @change="activateAlertaPush()" 
                            v-model="alert_switch"
                          >
                          <label
                            class="form-check-label link-cursor" 
                            for="switch-alerta-noticia"
                          >
                            Enviar alerta push
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
                  <button 
                    v-if="step == 'title'" 
                    class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                    @click="openQuestion()"
                    >
                    Cancelar
                  </button>
                  <button 
                    class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                    @click="handlePreStep()" 
                    v-if="step == 'content' || step == 'preview'"
                  >
                    Atrás
                  </button>
                  <button 
                    class="btn btn-custom-color-blue border-round-50 mw-100" 
                    :disabled="validForm"
                    @click="handleNextStep()"
                    v-if="display_button == 'next'"
                  >
                    Siguiente
                  </button>
                  <button 
                    class="btn btn-custom-color-blue border-round-50 mw-100" 
                    @click.stop="newsAdd()" 
                    :disabled="validForm"
                    v-if="display_button == 'publish'"
                  >
                    Agregar
                  </button>
                  <button 
                    class="btn btn-custom-color-blue border-round-50 mw-100" 
                    @click.stop="newsEdit" 
                    :disabled="validForm"
                    v-if="display_button == 'edit'"
                  >
                    Guardar
                  </button>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Status 
      v-if="open_modal_status"
      :msg="modal_status_msg" 
      :status="modal_status" 
      @close="acceptStatus" 
    />
    <Spinner v-if="show_spinner"/>
    <LoadingProgress 
      :value="loading_progress" 
      v-if="show_progress" 
      />  
    <Question 
      v-if="open_question_modal" 
      :msg="question_modal_msg" 
      :hideCancel="false" 
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    

  </section>

</template>

<script>
import UploadImg from "../UploadImg.vue";
import { Editor, EditorContent } from "@tiptap/vue-2";
import Link from "@tiptap/extension-link";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import Italic from "@tiptap/extension-italic";
import Underline from "@tiptap/extension-underline";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import CharacterCount from "@tiptap/extension-character-count";
import Status from "../Modales/Status.vue";
import { GET_CONTACTOS, GET_NOTICIA } from "@/apollo/queries";
import Spinner from "../Spinner.vue";
import { mapActions, mapState } from "vuex";
import Draggable from "vuedraggable";
import { apolloClient } from "@/vue-apollo";
import Question from "../Modales/Question.vue";
import LoadingProgress from "../LoadingProgress.vue";

export default {
  components: {
    UploadImg,
    EditorContent,
    Status,
    Spinner,
    Draggable,
    Question,
    LoadingProgress
  },
  data() {
    return {
      img_documento: require("@/assets/img/Descarga.svg"),
      user_default_img: require("@/assets/img/user_default_img.svg"),
      alerta_push: "0",
      alert_switch: false,
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      news_title: "",
      news_main_img: {
        width: "",
        height: "",
        base64: "",
        src: "",
        format: "",
      },
      comtent_objets_pre_edit: [],
      comtent_objets: [],
      component: {
        img: "",
        foto: "",
        url: "",
        video: "",
        video_prev: "",
        pdf: "",
        pdf_prev: "",
        videourl: "",
        pdf_name: "",
        user: "",
      },
      disabled_component: false,
      open_modal_status: false,
      loading_progress: 0,
      modal_status_msg: "",
      modal_status: true,
      bad_document: 0,
      show_component: "Texto",
      news_content: "",
      news_date: new Date(),
      step: "title",
      step_title: "Añade un título",
      min_date: new Date(),
      display_button: "next",
      editor_key: 1,
      editor_limit: 2000,
      editor: new Editor({
        extensions: [
          Bold,
          Link,
          Document,
          Paragraph,
          Text,
          Italic,
          Underline,
          OrderedList,
          BulletList,
          ListItem,
          CharacterCount.configure({ editor_limit: this.editor_limit }),
        ],
        content: this.content,
        autofocus: false,
        onUpdate: ({ editor }) => {
          this.news_content = editor.getHTML();
        },
      }),
      categoria: "",
      content: "",
      user_switch: "",
      user_name: "",
      user_list: [],
      user: {
        id_usuario: "",
        primer_nombre: "",
        apellido_paterno: "",
        link: "",
        medio: "",
        imagen_perfil: "",
      },
      name_comp: "",
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      calendar: new Date(),
      calendar_switch: false,
      key_calendar: 0,
      url_ex_app: "",
      url_ex_intranet: "",
      show_spinner: false,
      show_progress: false,
      open_select: false,
      cat_selected: "Selecciona una categoría",
      edit_noticia: false,
      edit_texto: false,
      edit_selected_texto: {},
      noticia_to_edit: {},
      tag_switch: "",
      video_switch: "",
      tags: [
        "Femenino",
        "Masculino",
        "Internos",
        "Externos",
        "Planta",
        "Teletrabajo",
        "Transporte ",
      ],
      hipervinculo_switch: false,
      addTags: [],
      dateTime: "00000",
      maskDateTime: "##:##",
      info: `<p style="font-size:16px;">Se visualizará en el carrusel del <strong>home</strong>  y en la sección de <strong>noticias</strong>.</p>
      <p style="font-size:16px;">Peso máximo: 2MB, Formato: PNG/JPG</p>`,
      meses: [
        "en",
        "febr",
        "mar",
        "abr",
        "my",
        "jun", 
        "jul",
        "agt",
        "set",
        "oct",
        "nov",
        "dic",
      ],
      calendar_msg: "",
      calendar_mod: true,
      is_programed: true,
      open_question_modal: false,
      question_modal_msg:
        "¿Realmente quieres salir? Se perderán los cambios realizados.",

      //FF
      elementos: [
        {
        id: 1,
        label: "Texto",
        component: "Texto",
        icon: "fa-solid fa-align-left"
        },
        {
        id: 2,
        label: "Imagen",
        component: "Imagen",
        icon: "fa-regular fa-image"
        },
        {
        id: 3,
        label: "Video",
        component: "Video",
        icon: "fa-solid fa-video"
        },
        {
        id: 4,
        label: "Documento",
        component: "Documento",
        icon: "fa-solid fa-file-lines"
        },
        {
        id: 5,
        label: "Autor",
        component: "Usuario",
        icon: "fa-solid fa-circle-user"
        },
      ]
    };
  },
  computed: {
    ...mapState("newsModule", ["news_list", "newsArr", "categories"]),

    // Funcion del plugin del calendario para destacar el dia seleccionado
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
    getMonth() {
      const date = new Date(this.news_date);
      let month = this.months[date.getMonth()];
      return month.slice(0, 3);
    },
    getDay() {
      const date = new Date(this.news_date);
      let day = "0" + date.getDate();
      return day.substr(-2);
    },
    categorias() {
      return this.categories;
    },

    //FF
    validForm() {
      if (this.step == "title" && this.news_title != "" > 0 && this.cat_selected !== "Selecciona una categoría" && this.news_main_img.base64 !== "" ) {
        return false;
      }
      if (this.step == "content" && this.comtent_objets.length > 0) {
        return false;
      }
      if (this.step == "preview") {
        return false;
      }
      else {
        return true;
      }
    },
    validContent() {
      let value = this.show_component;
      if (value === "Texto" && this.news_content != "" && this.news_content != "<p></p>") {
        return false;
      }
      if (value === "Imagen" && this.component.img) {
        return false;
      }
      if (value === "Video" && this.component.video.length > 0) {
        return false;
      }
      if (value === "Video" && this.component.videourl.length > 0) {
        return false;
      }
      if (value === "Documento" && this.component.pdf && this.component.pdf_name != "") {
        return false;
      }
      if (value === "Usuario" && this.user.primer_nombre) {
        return false;
      }
      else {
        return true
      }    
    }
  },
  mounted() {
    setTimeout(() => {
      this.editor = new Editor({
        extensions: [
          Bold,
          Link,
          Document,
          Paragraph,
          Text,
          Italic,
          Underline,
          OrderedList,
          BulletList,
          ListItem,
          CharacterCount.configure({ editor_limit: this.editor_limit }),
        ],
        content: this.content,
        autofocus: false,
        onUpdate: ({ editor }) => {
          this.news_content = editor.getHTML();
        },
      });
    }, 500);
    if (this.$route.params.id != undefined && this.$route.params.id != null) {
      this.getNoticia(this.$route.params.id);
    } else {
      const to_day = new Date();
      const fecha_d = to_day.getDate();
      const fecha_m = to_day.getMonth() + 1;

      this.news_date = `${to_day.getFullYear()}-${
        fecha_m < 10 ? `0${fecha_m}` : fecha_m
      }-${fecha_d < 10 ? `0${fecha_d}` : fecha_d}`;
    }
  },
  watch: {
    user_name: function () {
      if (this.user_name <= 1) {
        this.user_list = [];
      }
    },
  },
  methods: {
    ...mapActions("newsModule", ["addNews", "editNews"]),
        
    openQuestion() {
      this.open_question_modal = true;
    },

    cancelQuestion() {
      this.open_question_modal = false;
    },

    acceptQuestion() {
      this.open_question_modal = false;
      this.cleanComponent();
      let actual_route = this.$route.matched[1].path;
      if (actual_route == "/noticias/add") {
        this.$router.push("./list");
      } else {
        this.$router.push("../list");
      }
    },

    selectCategorias(cats) {
      //console.log(cats);
      let filterCats = cats.filter(function (element) {
        return element.especial == "0";
      });
      return filterCats;
    },

    activateAlertaPush() {
      //console.log('ALERT SWITCh', this.alert_switch);
      if (this.alert_switch) {
        this.alerta_push = "1";
      } else {
        this.alerta_push = "0";
      }
    },

    async getNoticia(id) {
      this.edit_noticia = true;
      this.calendar_switch = true;
      /* this.min_date = ""; */
      Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + h * 60 * 60 * 1000);
        return this;
      };
      let noticia = {};
      if (this.newsArr.length > 0) {
        noticia = this.newsArr.find((news) => news.id_noticia == id);
      } else {
        await apolloClient.query({
          query: GET_NOTICIA,
          variables: {
            id_usuario: this.$ls.get("user").id_usuario,
            id_empresa: this.id_empresa,
            id_noticia: id,
          },
          cachepolicy:'network-only'
        }).then(res => {
          if (res.data.noticia == null) {
            this.open_modal_status = true;
            this.modal_status_msg = "Ha ocurrido un problema, intenta nuevamente";
            this.modal_status = false;
          } else {
            noticia = res.data.noticia;
          }
        });
      }

      this.noticia_to_edit = noticia;
      if (this.noticia_to_edit.alerta_push == "1") {
        this.alert_switch = true;
      } else {
        this.alert_switch = true;
      }
      let fecha = new Date(noticia.fecha_noticia);
      let fecha_d = fecha.getDate();
      let fecha_m = fecha.getMonth() + 1;
      let fecha_h = fecha.getHours();
      let fecha_mi = fecha.getMinutes();

      /* console.log(fecha); */

      this.calendar = fecha;
      this.news_date = `${fecha.getFullYear()}-${
        fecha_m < 10 ? `0${fecha_m}` : fecha_m
      }-${fecha_d < 10 ? `0${fecha_d}` : fecha_d}`;

      this.dateTime = `${fecha_h < 10 ? `0${fecha_h}` : fecha_h}:${
        fecha_mi < 10 ? `0${fecha_mi}` : fecha_mi
      }`;

      this.key_calendar = this.key_calendar + 1;
      this.news_title = noticia.titulo_noticia;
      this.cat_selected = noticia.categoria[0];
      
      this.news_main_img = {
        width: "",
        height: "",
        base64: this.noticia_to_edit.url_imagen_principal,
        src: this.noticia_to_edit.url_imagen_principal,
        format:
          this.noticia_to_edit.url_imagen_principal.split(".")[
            this.noticia_to_edit.url_imagen_principal.split(".").length - 1
          ],
      };
      for (let i = 0; i < noticia.contenido.length; i++) {
        this.show_component = noticia.contenido[i].tipo_contenido[0].id_tipo_contenido;
        switch (this.show_component) {
          case "1":
            this.comtent_objets.push({
              title: `Texto;(${this.duplicate("Texto") + 1})`,
              tipo: "Texto",
              id: noticia.contenido[i].id_contenido,
              contenido: noticia.contenido[i].texto,
            });
            setTimeout(() => {
              this.editor = new Editor({
                extensions: [
                  Bold,
                  Link,
                  Document,
                  Paragraph,
                  Text,
                  Italic,
                  Underline,
                  OrderedList,
                  BulletList,
                  ListItem,
                  CharacterCount.configure({
                    editor_limit: this.editor_limit,
                  }),
                ],
                content: this.content,
                autofocus: false,
                onUpdate: ({ editor }) => {
                  this.news_content = editor.getHTML();
                },
              });

              this.editor_key = 1 + this.editor_key;
            }, 200);

            break;
          case "2":
            this.comtent_objets.push({
              title: `Imagen;(${this.duplicate("Imagen") + 1})`,
              tipo: "Imagen",
              id: noticia.contenido[i].id_contenido,
              contenido: {
                foto: noticia.contenido[i].url_imagen,
                img: noticia.contenido[i].url_imagen,
                url: noticia.contenido[i].url_externo_intranet,
              },
            });
            break;
          case "3":
            this.comtent_objets.push({
              title: `Video;(${this.duplicate("Video") + 1})`,
              tipo: "Video",
              id: noticia.contenido[i].id_contenido,
              contenido: {
                video: noticia.contenido[i].url_video,
                url: noticia.contenido[i].url_video,
              },
            });
            break;
          case "4":
            this.comtent_objets.push({
              title: `Documento;(${this.duplicate("Documento") + 1})`,
              tipo: "Documento",
              id: noticia.contenido[i].id_contenido,
              contenido: {
                pdf: noticia.contenido[i].url_documento,
                url: noticia.contenido[i].url_documento,
                name: noticia.contenido[i].texto,
              },
            });
            break;
          case "5":
            this.comtent_objets.push({
              title: `Reportero;(${this.duplicate("Reportero") + 1})`,
              tipo: `Reportero`,
              id: noticia.contenido[i].id_contenido,
              id_usuario: noticia.contenido[i].usuario[0].id_usuario,
              contenido: {
                type: this.user_switch == "" ? 1 : 2,
                id_usuario: noticia.contenido[i].usuario[0].id_usuario,
                primer_nombre: noticia.contenido[i].usuario[0].primer_nombre,
                apellido_paterno:
                  noticia.contenido[i].usuario[0].apellido_paterno,
                user: noticia.contenido[i].usuario[0],
                link: this.user.link,
                medio: this.user.medio,
                imagen_perfil: noticia.contenido[i].usuario[0].imagen_perfil,
              },
            });

            break;
          default:
            //console.log("no existe el tipo de componente");
            break;
        }
      }
      // aqui
      this.show_component = "Texto";
      this.comtent_objets_pre_edit = this.comtent_objets;

      let f_p = noticia.fecha_noticia;
      let f_c = noticia.fecha_creacion;
      let f_hoy = new Date();
      if (f_hoy > new Date(f_p)) {
        this.calendar_mod = false;
      } else {
        this.calendar_mod = true;
      }
      //console.log("FP", noticia.fecha_noticia);
      if (f_p == f_c && this.dateTime == "00:00") {
        //console.log("no programada");
        this.calendar_msg = `Esta noticia fue publicada el ${this.dateNewFormat(
          f_p,
          2
        )}`;
        this.calendar_mod = false;
        this.is_programed = false;
      } else {
        //console.log("programada", this.calendar_mod);
        if(this.calendar_mod) this.is_programed = true;
        if(this.calendar_mod == false) this.is_programed = false;
        this.min_date = new Date();
        this.calendar_msg = this.calendar_mod
          ? `Noticia programada para el ${this.dateNewFormat(f_p, 2)}`
          : `Noticia publicada el ${this.dateNewFormat(f_p,2)}`;
      }
      if (noticia.alerta_push == "1") {
        this.alert_switch = true;
        this.alerta_push = "1";
      }
      // if (
      //   this.comtent_objets[0] != undefined &&
      //   this.comtent_objets[0] != null
      // ) {
      //   setTimeout(() => {
      //     this.editText(this.comtent_objets[0], 0);
      //     if (this.comtent_objets[0].tipo == "Texto") {
      //       this.news_content = this.comtent_objets[0].contenido;
      //     }
      //   }, 600);
      // }
    },
    // publicar noticia
    async newsAdd() {
      this.show_spinner = true;
      const data = this.dataForNews();
      console.log(data);
      const res = await this.addNews(data);
      console.log(res);
      if(res != null) {
        this.toastNewsAddSuccess();
        
        this.$router.push({        
        name: "news-list",
      });
      } else {
        this.toastNewsAddError();
      } 
      this.show_spinner = false;
      
    },
    //variables de contenido para publicar
    dataForNews() {
      let content = [];
      let format = [];
      let type = [];
      let doc_name = [];
      let va = [];
      
      const e = this.comtent_objets;
      for (let i = 0; i < e.length; i++) {
        switch (e[i].tipo) {
          case "Texto":
            content.push(e[i].contenido);
            format.push("");
            type.push("1");
            doc_name.push("");
            va.push("");

            break;
          case "Imagen":
            content.push(e[i].contenido.foto);
            format.push(`${e[i].contenido.img.split(":")[1].split(";")[0]}`);
            type.push("2");
            doc_name.push("");
            va.push(`${e[i].contenido.url}`);
            break;
          case "Video":
            content.push(e[i].contenido.video);
            format.push(`${e[i].contenido.url.split(":")[1].split(";")[0]}`);
            type.push("3");
            doc_name.push("");
            va.push("");
            break;
          case "Documento":
            content.push(e[i].contenido.pdf);
            format.push(`${e[i].contenido.url.split(":")[1].split(";")[0]}`);
            type.push("4");
            doc_name.push(e[i].contenido.name);
            va.push("");
            break;
          case "Reportero":
            if (e[i].contenido.type == 1) {
              content.push(e[i].contenido.id_usuario);
              format.push(``);
              type.push("5");
              doc_name.push("");
              va.push("");
            }
            if (e[i].contenido.type == 2) {
              content.push(e[i].contenido.id_usuario);
              format.push(``);
              type.push("5");
              doc_name.push("");
              va.push("");
            }
        }
      }
      const content_request = {
        id_empresa_fk: this.id_empresa,
        titulo: this.news_title,
        id_categoria: this.cat_selected.id_categoria,
        tipo_contenido: type,
        contenido: content,
        formato_contenido: format,
        nombre_documento: doc_name,
        id_tipo: "2",
        imagen_principal: this.news_main_img.src,
        formato_principal: `image/${this.news_main_img.format}`,
        fecha_noticia: `${this.news_date} ${this.dateTime}:00`,
        url_externo_app: va,
        url_externo_intranet: va,
        tipo_segementacion: "0",
        segmentaciones: "0",
        id_creador: this.$ls.get("user").id_usuario,
        alerta_push: this.alerta_push,
      };
      return content_request;
    },
    // publicar la edicion de la noticia
    async newsEdit() {
      this.show_spinner = true;
      const data = this.varPublishEdit();
      const res = await this.editNews(data);
      if (res != null) {
        this.toastNewsEditSuccess();
        this.$router.push({
          name: "news-list",
        });
      } else {
        this.toastNewsEditError();
      }
      this.show_spinner = false;
      
    },
    //variables de contenido para publicar la edicion
    varPublishEdit() {
      const e = this.comtent_objets;
      if (this.compareArray(e, this.comtent_objets_pre_edit)) {
        let content = [];
        let format = [];
        let type = [];
        let id = [];
        let doc_name = [];
        let va = [];
        for (let i = 0; i < e.length; i++) {
          //console.log(e[i].id == undefined);
          switch (e[i].tipo) {
            case "Texto":
              content.push(
                e[i].contenido == null ? "delete" : `${e[i].contenido}`
              );
              /* <iframe src="https://www.youtube.com/embed/s-jtdKjzQaE" ></iframe> */
              format.push("");
              type.push("1");
              id.push(e[i].id == undefined ? "" : e[i].id);
              doc_name.push("");
              va.push("");
              break;
            case "Imagen":
              content.push(
                e[i].contenido == null
                  ? "delete"
                  : e[i].id == undefined
                  ? e[i].contenido.foto
                  : ""
              );
              format.push(
                e[i].contenido == null
                  ? ""
                  : e[i].id == undefined
                  ? `${e[i].contenido.img.split(":")[1].split(";")[0]}`
                  : ""
              );
              this.url_ex_app =
                e[i].contenido == null ? "" : e[i].contenido.url;
              this.url_ex_intranet =
                e[i].contenido == null ? "" : e[i].contenido.url;
              id.push(e[i].id == undefined ? "" : e[i].id);
              type.push("2");
              doc_name.push("");
              va.push(e[i].contenido == null ? "" : e[i].contenido.url);
              break;
            case "Video":
              content.push(
                e[i].contenido == null
                  ? "delete"
                  : e[i].id == undefined
                  ? e[i].contenido.video
                  : ""
              );
              format.push(
                e[i].contenido == null
                  ? ""
                  : e[i].id == undefined
                  ? `${e[i].contenido.url.split("/")[1].split(";")[0]}`
                  : e[i].contenido.url.split(".")[
                      e[i].contenido.url.split(".").length - 1
                    ]
              );
              type.push("3");
              id.push(e[i].id == undefined ? "" : e[i].id);
              doc_name.push("");
              va.push(e[i].contenido == null ? "" : e[i].contenido.url);
              break;
            case "Documento":
              //console.log('mydoc',e[i].contenido)
              content.push(
                e[i].contenido == null
                  ? "delete"
                  : e[i].id == undefined
                  ? e[i].contenido.pdf
                  : ""
              );
              format.push(
                e[i].contenido == null
                  ? ""
                  : e[i].id == undefined
                  ? `${e[i].contenido.url.split("/")[1].split(";")[0]}`
                  : e[i].contenido.url.split(".")[
                      e[i].contenido.url.split(".").length - 1
                    ]
              );
              type.push("4");
              id.push(e[i].id == undefined ? "" : e[i].id);
              doc_name.push(e[i].contenido == null ? "" : e[i].contenido.name);
              va.push("");
              break;
            case "Reportero":
              if (e[i].contenido != null) {
                //console.log("nuevo reportero", e[i].contenido);
                if (e[i].contenido.type == 1) {
                  content.push(
                    e[i].contenido == null ? "" : e[i].contenido.id_usuario
                  );
                  format.push(``);
                  type.push("5");
                  id.push(e[i].id == undefined ? "" : e[i].id);
                  doc_name.push("");
                  break;
                }
                if (e[i].contenido.type == 2) {
                  content.push(
                    e[i].contenido == null ? "" : e[i].contenido.id_usuario
                  );
                  format.push(``);
                  type.push("5");
                  id.push(e[i].id == undefined ? "" : e[i].id);
                  doc_name.push("");
                  break;
                }
              } else {
                //console.log("Elemento report", e[i]);
                content.push("delete");
                format.push(``);
                type.push("5");
                id.push(e[i].id);
                doc_name.push("");
                break;
              }
              va.push("");
          }
        }
        const content_request = {
          id_noticia: this.noticia_to_edit.id_noticia,
          id_empresa_fk: this.id_empresa,
          titulo: this.news_title,
          id_categoria: this.cat_selected.id_categoria,
          tipo_contenido: type,
          id_contenidos: id,
          contenido: content,
          formato_contenido: format,
          nombre_documento: doc_name,
          formato_app: "1",
          id_tipo: "2",
          imagen_principal:
            this.news_main_img.src == this.noticia_to_edit.url_imagen_principal
              ? ""
              : this.news_main_img.src,
          formato_principal: this.news_main_img.format,
          fecha_noticia: `${this.news_date} ${this.dateTime}:00`,
          url_externo_app: va,
          url_externo_intranet: va,
          tipo_segementacion: "",
          segmentaciones: "",
          id_modificador: this.$ls.get("user").id_usuario,
          alerta_push: this.alerta_push,
        };
        console.log("CONTENT_REQUEST", content_request);
        return content_request;
      } else {
        return null;
      }
    },
    //compara dos array
    compareArray(a, b) {
      //console.log("a:", a);
      //console.log("b", b);
      let iguales = 0;
      const arr_one = a.length;
      if (arr_one == b.length) {
        for (let i = 0; i < arr_one; i++) {
          if (JSON.stringify(a[i]) === JSON.stringify(b[i])) {
            iguales = iguales + 1;
          }
        }
      }
      if (arr_one == iguales) {
        return true;
      } else {
        return false;
      }
    },
    // Obteniendo usuarios
    getUsers() {
      if (this.user_name.length > 0) {
        this.$apollo
          .query({
            query: GET_CONTACTOS,
            variables: {
              id_empresa: this.id_empresa,
              nombre_buscar: this.user_name,
              id_gerencia: "0",
            },
          })
          .then((res) => {
            let resp = res.data.contactos;
            this.user_list = resp;
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },

    // Funcion para mostrar el numero de cada paso
    showSection(section) {
      switch (section) {
        case "main-img":
          if (this.step == "main-img") {
            return true;
          }
          break;
        case "title":
          if (this.step == "title") {
            return true;
          }
          break;
        case "content":
          if (this.step == "content") {
            return true;
          }
          break;
        case "preview":
          if (this.step == "preview") {
            return true;
          }
          break;
        case "date":
          if (this.step == "date") {
            return true;
          }
          break;
        default:
          break;
      }
    },
    // Funcion para manejar las clases de cada paso
    handleStepsClass(step) {
      switch (step) {
        case 1:
          if (this.step == "title") {
            return "active";
          } else if (
            this.news_title != "" &&
            this.cat_selected !== "Selecciona una categoría" &&
            this.news_main_img.base64 !== ""
          ) {
            return "ok";
          }
          break;
        case 3:
          if (this.step == "content") {
            return "active";
          } else if (this.comtent_objets.length > 0) {
            return "ok";
          }
          break;
        case 4:
          if (this.step == "preview") {
            return "active";
          } else if (
            this.news_title != "" &&
            this.cat_selected !== "Selecciona una categoría" &&
            this.news_main_img.base64 !== "" &&
            this.comtent_objets.length > 0 &&
            this.edit_noticia
          ) {
            return "ok";
          }
          break;

        default:
          break;
      }
    },
    // Funcion para manejar las clases de cada paso
    handleStepsClassNext() {
      if (
        this.step == "title" &&
        this.news_title != "" > 0 &&
        this.cat_selected !== "Selecciona una categoría" &&
        this.news_main_img.base64 !== ""
      ) {
        return "active";
      }
      if (this.step == "content" && this.comtent_objets.length > 0) {
        return "active";
      }
      if (this.step == "preview") {
        return "active";
      }
    },
    handleStepsClassAgnadir() {
      switch (this.show_component) {
        case "Texto":
          if (this.news_content != "" && this.news_content != "<p></p>") {
            return "active";
          }
          break;
        case "Imagen":
          if (this.component.img) {
            return "active";
          }
          break;
        case "Video":
          if (this.component.video.length > 0) {
            return "active";
          }
          if (this.component.videourl.length > 0) {
            return "active";
          }
          break;
        case "Documento":
          if (this.component.pdf && this.component.pdf_name != "") {
            return "active";
          }
          break;
        case "Usuario":
          if (this.user.primer_nombre) {
            return "active";
          }
          break;
        default:
          return "";
      }
    },
    // Funcion para avanzar al siguiente paso
    handleNextStep() {
      if (
        this.step == "title" &&
        this.news_title.length > 0 &&
        this.cat_selected !== "Selecciona una categoría" &&
        this.news_main_img.base64 !== ""
      ) {
        this.step = "content";
        this.step_title = "Selecciona elemento";
        this.display_button = "next";
      } else if (this.step == "content" && this.comtent_objets.length > 0) {
        this.step = "preview";
        this.step_title = "Vista previa de tu noticia";
        this.display_button = this.edit_noticia == false ? "publish" : "edit";
      }
    },
    handlePreStep() {
      if (this.step == "content") {
        this.clickSteps(1);
      } else if (this.step == "preview") {
        this.clickSteps(3);
      }
    },
    // Funcion para navegar entre pasos
    clickSteps(step) {
      if (this.step == step) {
        return;
      } else {
        if (step == 1) {
          this.step = "title";
          this.display_button = "next";
          this.step_title = "Añade un título";
          // setTimeout(() => {
          //   this.$refs.newsTitle.focus();
          // }, 50);
        } else if (
          step == 2 &&
          this.news_title.length > 0 &&
          this.cat_selected !== "Selecciona una categoría"
        ) {
          this.step = "main-img";
          this.display_button = "next";
          this.step_title = "Subir imagen principal";
        } else if (step == 3 && this.news_main_img.base64 !== "") {
          this.step = "content";
          this.display_button = "next";
          this.step_title = "Selecciona elemento";
          // setTimeout(() => {
          //   this.$refs.newsTitle.focus();
          // }, 50);
        } else if (step == 4 && this.comtent_objets.length > 1) {
          this.step = "preview";
          this.display_button = this.edit_noticia == false ? "publish" : "edit";
          this.step_title = "Vista previa de tu noticia";
        }
      }
    },
    // Funcion para seleccionar fecha
    onDayClick(day) {
      //console.log(day);
      if (day.isDisabled == false) {
        this.news_date = day.id;
      }
    },
    // Funcion para subir imagen principal
    handleMainImg(img) {
      this.news_main_img = {
        width: img.width,
        height: img.height,
        base64: img.base64,
        src: img.src,
        format: img.format,
      };
      // console.log(this.post_img);
    },
    // Funcion para subir imagen secundaria
    handleSecImg(img) {
      this.news_sec_img = {
        width: img.width,
        height: img.height,
        base64: img.base64,
        src: img.src,
        format: img.format,
      };
      // console.log(this.post_img);
    },
    // Funcion para agregar enlaces en el texto de contenido
    setLink() {
      const url = window.prompt("Ingresa la url del enlace");
      this.editor.chain().focus().toggleLink({ href: url }).run();
      alert("Enlace agregado, ahora puedes escribir el texto de tu noticia.");
    },
    // Funcion para eliminar vista previa de imagenes subidas, img_type = puede ser img principal o secundaria
    deletePreview(img_type) {
      if (img_type == "main") {
        this.news_main_img = {
          width: "",
          height: "",
          base64: "",
          src: "",
          format: "",
        };
      }
      if (img_type == "sec") {
        this.news_sec_img = {
          width: "",
          height: "",
          base64: "",
          src: "",
          format: "",
        };
      }
    },
    upList(i) {
      //console.log(this.comtent_objets);
      let element = this.comtent_objets[i];
      //console.log(element);
      this.comtent_objets.splice(i, 1);
      this.comtent_objets.splice(i - 1, 0, element);
    },
    push() {
      //console.log(this.user);
      switch (this.show_component) {
        case "Texto":
          if (this.news_content !== "" && this.news_content != "<p></p>") {
            this.comtent_objets.push({
              title: `Texto;(${this.duplicate("Texto") + 1})`,
              tipo: "Texto",
              contenido: this.news_content,
            });
            this.news_content = "";
            setTimeout(() => {
              this.editor = new Editor({
                extensions: [
                  Bold,
                  Link,
                  Document,
                  Paragraph,
                  Text,
                  Italic,
                  Underline,
                  BulletList,
                  OrderedList,
                  ListItem,
                  CharacterCount.configure({
                    editor_limit: this.editor_limit,
                  }),
                ],
                content: this.content,
                autofocus: false,
                onUpdate: ({ editor }) => {
                  this.news_content = editor.getHTML();
                },
              });
              this.editor_key = 1 + this.editor_key;
            }, 200);
          }
          this.name_comp = "";
          break;
        case "Imagen":
          if (this.component.img) {
            this.comtent_objets.push({
              title: `Imagen;(${this.duplicate("Imagen") + 1})`,
              tipo: "Imagen",
              contenido: {
                foto: this.component.foto,
                img: this.component.img,
                url: this.component.url,
              },
            });
            this.component.foto = "";
            this.component.img = "";
            this.component.url = "";
            this.hipervinculo_switch = false;
          }
          this.name_comp = "";
          break;
        case "Video":
          if (this.component.video.length > 0) {
            this.comtent_objets.push({
              title: `Video;(${this.duplicate("Video") + 1})`,
              tipo: "Video",
              contenido: {
                video: this.component.video,
                url: this.component.video_prev,
              },
            });
            this.component.video = "";
            this.component.video_prev = "";
          }
          if (this.component.videourl.length > 0) {
            this.comtent_objets.push({
              title: `Video;(${this.duplicate("Video") + 1})`,
              tipo: "Video",
              contenido: {
                video: this.component.videourl,
                url: this.component.videourl,
              },
            });
            this.component.videourl = "";
          }
          this.name_comp = "";
          break;
        case "Documento":
          if (this.component.pdf && this.component.pdf_name != "") {
            this.comtent_objets.push({
              title: `Documento;(${this.duplicate("Documento") + 1})`,
              tipo: "Documento",
              contenido: {
                pdf: this.component.pdf,
                url: this.component.pdf_prev,
                name: this.component.pdf_name,
              },
            });
            this.component.pdf = "";
            this.component.pdf_prev = "";
            this.component.pdf_name = "";
          }
          this.name_comp = "";
          break;
        case "Usuario":
          if (this.user.primer_nombre) {
            this.comtent_objets.push({
              title: `Reportero;(${this.duplicate("Reportero") + 1})`,
              tipo: `Reportero`,
              contenido: {
                type: this.user_switch == "" ? 1 : 2,
                id_usuario: this.user.id_usuario,
                primer_nombre: this.user.primer_nombre,
                apellido_paterno: this.user.apellido_paterno,
                link: this.user.link,
                medio: this.user.medio,
                user: this.user,
                imagen_perfil: this.user.imagen_perfil,
              },
            });
            this.user_name = ``;
            this.user_list = [];

            this.user = {
              id_usuario: "",
              primer_nombre: "",
              apellido_paterno: "",
              link: "",
              medio: "",
              imagen_perfil: "",
            };
            this.name_comp = "";
          }
          break;
        default:
          //console.log("no existe el tipo de componente");
          break;
      }
    },
    pushEdited() {
      let index = this.comtent_objets.findIndex(
        (ob) => ob.title == this.edit_selected_texto.title
      );
      this.edit_texto = false;
      switch (this.show_component) {
        case "Texto":
          //console.log("content", this.news_content);
          if (this.news_content != "") {
            this.comtent_objets.splice(index, 1, {
              title: this.edit_selected_texto.title,
              tipo: "Texto",
              contenido: this.news_content,
              id: this.edit_selected_texto.id,
            });
            this.cleanComponent();
          }
          this.name_comp = "";
          break;
        case "Imagen":
          if (this.component.img) {
            this.comtent_objets.splice(index, 1, {
              title: this.edit_selected_texto.title,
              tipo: "Imagen",
              contenido: {
                foto: this.component.foto,
                img: this.component.img,
                url: this.component.url,
              },
              id: this.edit_selected_texto.id,
            });
            this.component.foto = "";
            this.component.img = "";
            this.component.url = "";
            this.hipervinculo_switch = false;
          }
          this.name_comp = "";
          break;
        case "Video":
          this.comtent_objets.splice(index, 1, {
            title: this.edit_selected_texto.title,
            tipo: "Video",
            contenido: {
              video: this.component.video,
              url: this.component.video_prev,
            },
            id: this.edit_selected_texto.id,
          });
          this.component.video = "";
          this.component.video_prev = "";
          this.name_comp = "";
          break;
        case "Documento":
          if (this.component.pdf) {
            this.comtent_objets.splice(index, 1, {
              title: this.edit_selected_texto.title,
              tipo: "Documento",
              contenido: {
                pdf: this.component.pdf,
                url: this.component.pdf_prev,
                name: this.component.pdf_name,
              },
              id: this.edit_selected_texto.id,
            });
            this.component.pdf = "";
            this.component.pdf_prev = "";
            this.component.pdf_name = "";
          }
          this.name_comp = "";
          break;
        case "Usuario":
          if (this.component) {
            this.comtent_objets.splice(index, 1, {
              title: this.edit_selected_texto.title,
              tipo: "Reportero",
              id_usuario: this.component.id_usuario,
              contenido: {
                type: this.component.type,
                id_usuario: this.component.id_usuario,
                primer_nombre: this.component.user.primer_nombre,
                imagen_perfil: this.component.user.imagen_perfil,
                apellido_paterno: this.component.apellido_paterno,
                user: this.component.user,
                link: this.component.link,
                medio: this.component.medio,
              },
              id: this.edit_selected_texto.id,
            });
            this.component.pdf = "";
            this.component.pdf_prev = "";
            this.component.pdf_name = "";
          }
          this.name_comp = "";
          break;
        default:
          //console.log("no existe el tipo de componente");
          break;
      }

      this.edit_selected_texto = {};
    },
    cleanComponent() {
      //console.log("limpiando componente");
      this.component = {
        img: "",
        foto: "",
        url: "",
        video: "",
        video_prev: "",
        pdf: "",
        pdf_prev: "",
        videourl: "",
        pdf_name: "",
      };
      this.user_name = "";
      this.user_list = [];
      this.user = {
        id_usuario: "",
        primer_nombre: "",
        apellido_paterno: "",
        link: "",
        medio: "",
        imagen_perfil: "",
      };
      this.news_content = "";
      setTimeout(() => {
        this.editor = new Editor({
          extensions: [
            Bold,
            Link,
            Document,
            Paragraph,
            Text,
            Italic,
            Underline,
            BulletList,
            OrderedList,
            ListItem,
            CharacterCount.configure({
              editor_limit: this.editor_limit,
            }),
          ],
          content: this.content,
          autofocus: false,
          onUpdate: ({ editor }) => {
            this.news_content = editor.getHTML();
          },
        });
        this.editor_key = 1 + this.editor_key;
      }, 200);
    },
    duplicate(dupli) {
      let filt = this.comtent_objets.filter((el) => el.tipo == dupli);
      //console.log(dupli,filt);
      return filt.length;
    },
    loadPreview(e) {
      // console.log("LOAD ", e);
      switch (e.tipo) {
        case "Texto":
          return e.contenido == null ? "" : e.contenido;
        case "Imagen":
          return e.contenido == null
            ? ""
            : `<a href="${e.contenido.url}" target="_blank" ><img src="${e.contenido.img}" alt="" /></a>`;
        case "Video":
          return e.contenido == null
            ? ""
            : `
            <video
              controls
              src="${e.contenido.url}"
              alt="Video"
              >
            </video>
                  `;
        case "Documento":
          return e.contenido == null
            ? ""
            : `
            <a target="_blank" href="${e.contenido.url}">
              Ver documento:  ${e.contenido.name}</a>
            <img src="${this.img_documento}" height="20" alt=""> 
            `;
        case "Reportero":
          if (e.contenido != null) {
            if (e.contenido.type == 1) {
              if (e.contenido.imagen_perfil !== null){
                return `
                <img src="${e.contenido.imagen_perfil}" height="20" alt="">
                <div class="reporter"><div class="autor">Autor</div>
                  <div>${e.contenido.primer_nombre} ${e.contenido.apellido_paterno}</div>
                </div> 
                `;
              } else {
                return `
                <img src="${this.user_default_img}" height="20" alt="">
                <div class="reporter"><div class="autor">Autor</div>
                  <div>${e.contenido.primer_nombre} ${e.contenido.apellido_paterno}</div>
                </div> 
                `;
              }

            }
            if (e.contenido.type == 2) {
              return `<p>${e.contenido.primer_nombre} ${e.contenido.apellido_paterno} reportero de <a target="_blank" href="${e.contenido.link}">${e.contenido.medio}</a> </p>`;
            }
          } else {
            return "";
          }
      }
    },
    loadFiles(eve) {
      let file = eve.target;
      let type = file.files[0].type.split("/")[0];
      let file_name = file.files[0].name;

      let rea = new FileReader();
      if (file.files) {
        switch (type) {
          case "image":
            if (file.files[0].size < 2 * 1024*1024) {
              rea.onload = (e) => {
                this.component.foto =
                  e.target.result.split(",")[
                    e.target.result.split(",").length - 1
                  ];
                this.component.img = e.target.result;
              };
              rea.readAsDataURL(file.files[0]);
            } else {
              alert("la imagen excede el tamaño maximo de 2 megas");
            }
            break;
          case "video":
            if (file.files[0].size < 100 * 1024*1024) {

              rea.onloadstart = () => {
                this.show_progress = true;
              };

              rea.onload = (e) => {
                this.component.video =
                  e.target.result.split(",")[
                    e.target.result.split(",").length - 1
                  ];
                this.component.video_prev = e.target.result;
                this.loading_progress = 100;
              };

            rea.onprogress = (data) => {
              if (data.lengthComputable) {                                            
                this.loading_progress = parseInt( ((data.loaded / data.total) * 100), 10 );
              }
            };

            rea.onloadend = () => {
              this.show_progress = false;
            };


            rea.readAsDataURL(file.files[0]);
            } else {
              alert("el video excede el tamaño maximo de 100 megas");
            }
            break;
          case "application":
            if (file.files[0].size < 10 * 1024 * 1024) {
              rea.onload = (e) => {
                //console.log(e.target.result);
                this.component.pdf =
                  e.target.result.split(",")[
                    e.target.result.split(",").length - 1
                  ];
                this.component.pdf_prev = e.target.result;
                this.component.docname = file_name;
                this.component.size = file.files[0].size / 1000000;
              };
              rea.readAsDataURL(file.files[0]);
            } else {
              this.open_modal_status = true;
              this.file_limit_mb = true;
              this.modal_status = false;
              this.modal_status_msg = "El documento excede los 10MB";
              this.bad_document = 1;
            }
            break;
        }
      }
    },
    acceptStatus() {
      this.open_modal_status = false;
      this.modal_status_msg = "";
      if (this.bad_document == 0) {
        this.bad_document = 0;
        this.$router.push({
          name: "news-list",
        });
      }
    },
    // funcion para abrir y cerrar select de categorias
    toggleSelect() {
      if (this.open_select) {
        this.open_select = false;
      } else {
        this.open_select = true;
      }
    },
    selectCategoria(cat) {
      this.categoria = cat.id_categoria;
      this.cat_selected = cat.nombre_categoria;
      setTimeout(() => {
        this.open_select = false;
      }, 100);
    },
    onDrop() {
      //console.log(this.comtent_objets);
    },
    deleteComponent(co, i) {
      console.table ("Delete", co, this.comtent_objets[i]);
      if (co.id == undefined) {
        this.comtent_objets.splice(i, 1); //nuevos
      } else {
        this.comtent_objets[i].contenido = null;
         //desde el ws
      }
      //console.log("Elimiando: ", this.comtent_objets[i]);
    },
    editText(comp, index) {
      //console.log("comp", comp, index);
      switch (comp.tipo) {
        case "Texto":
          this.edit_selected_texto = this.comtent_objets[index];
          this.edit_selected_texto.index = index;
          this.show_component = "Texto";
          this.editor = new Editor({
            extensions: [
              Bold,
              Link,
              Document,
              Paragraph,
              Text,
              Italic,
              Underline,
              BulletList,
              OrderedList,
              ListItem,
              CharacterCount.configure({
                editor_limit: this.editor_limit,
              }),
            ],
            content: comp.contenido,
            autofocus: false,
            onUpdate: ({ editor }) => {
              this.news_content = editor.getHTML();
            },
          });
          this.news_content = comp.contenido;
          this.editor_key = 1 + this.editor_key;
          this.edit_texto = true;
          break;
        case "Imagen":
          this.edit_selected_texto = this.comtent_objets[index];
          this.edit_selected_texto.index = index;
          this.show_component = "Imagen";
          this.hipervinculo_switch = comp.contenido.url != "";
          this.component.foto = comp.contenido.foto;
          this.component.img = comp.contenido.img;
          this.component.url = comp.contenido.url;
          this.edit_texto = true;
          break;
        case "Video":
          this.edit_selected_texto = this.comtent_objets[index];
          this.edit_selected_texto.index = index;
          this.show_component = "Video";
          this.component.video = comp.contenido.url;
          this.component.video_prev = comp.contenido.url;
          this.component.videourl = comp.contenido.url;
          this.component.url = comp.contenido.url;
          this.edit_texto = true;
          break;
        case "Documento":
          this.edit_selected_texto = this.comtent_objets[index];
          this.edit_selected_texto.index = index;
          this.show_component = "Documento";

          this.component.pdf = comp.contenido.url;
          this.component.pdf_prev = comp.contenido.url;
          this.component.pdf_name = comp.contenido.name;
          this.component.docname = comp.contenido.name;
          this.component.size = 0;

          this.edit_texto = true;
          break;
        case "Reportero":
          this.edit_selected_texto = this.comtent_objets[index];
          this.edit_selected_texto.index = index;
          this.show_component = "Usuario";
          this.component.apellido_paterno = comp.contenido.apellido_paterno;
          this.component.id_usuario = comp.contenido.id_usuario;
          this.component.primer_nombre = comp.contenido.primer_nombre;
          this.component.medio = "";
          this.component.link = "";
          this.component.segundo_nombre = comp.contenido.segundo_nombre;
          this.component.perfil = comp.contenido.perfil;
          this.component.user = comp.contenido.user;
          this.component.type = comp.contenido.type;

          this.edit_texto = true;
          this.user_list.push(this.component.user);
          //console.log('COMPONENT REPORTERO',this.component);
          break;
        default:
          //console.log("no existe el tipo de componente");
          break;
      }
    },
    //Formatear fecha
    dateNewFormat(date, type) {
      let date_day = new Date(date);
      let date_f = new Date(date);
      date_f.setDate(date_f.getDate() + 1);
      let month = date_f.getMonth();

      if (type == 1) {
        return `${date_f.getDate()};${this.meses[month]}`;
      }
      if (type == 2) {
        return `${date_day.getDate()}/${
          month + 1 < 10 ? `0${month + 1}` : month + 1
        }/${date_day.getFullYear()}`;
      }
    },
    cancelEdit() {
      this.edit_texto = false;
      this.edit_selected_texto = {};
      this.cleanComponent();
    },

    toastNewsAddSuccess() {
      this.$toast.open({
        type: 'success',
        message: 'Noticia creada correctamente.',
        duration: 5000,
        position:'top-right'
      });
    },
    toastNewsAddError() {
      this.$toast.open({
        type: 'error',
        message: 'Ocurrió un error al crear la noticia. Por favor inténtalo nuevamente.',
        duration: 0,
        position:'top-right'
      });    
    },
    toastNewsEditSuccess() {
      this.$toast.open({
        message: 'Noticia editada correctamente.',
        type: 'success',
        duration: 5000,
        position: 'top-right'
      });
    },
    toastNewsEditError() {
      this.$toast.open({
        message: 'Ocurrió un error al editar la noticia. Por favor inténtalo nuevamente.',
        type: 'error',
        duration: 5000,
        position: 'top-right'
      });
    }
  },
    //FF
    filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>
