<template>
	<div class="loading-progress">
		<progress-bar :value="roundedValue" :options="options" />
	</div>
</template>
 
<script>
export default {
	props: {
		value: {
			type: Number
		},
		options: {
			type: Object,
			default() {
				return {
					text: {
						color: "grey",
						shadowEnable: false,
						shadowColor: "ff6e00",
						fontSize: 25,
						fontFamily: "Product Sans Regular",
						dynamicPosition: false,
						hideText: false,
					},
					progress: {
						color: "#0072f7",
						backgroundColor: "#F8F8F8",
					},
					layout: {
						height: 300,
						width: 140,
						verticalTextAlign: 160,
						horizontalTextAlign: 50,
						strokeWidth: 12,
						type: "circle",
					},
				}
			}
		}
	},

	computed: {
		roundedValue() {
			return Math.round(this.value);
		}
	}

};
</script>