<template>
   <div class="upload-image" 
      @keypress.enter="crop()">
      <label 
         class="upload-image-label" 
         for="input-file" 
         v-if="!show_preview_img"
      >
         <span class="upload-image-label-icon">
            <i class="fa-solid fa-arrow-up-from-bracket color-secondary"></i>
         </span>
         <input
            type="file"
            id="input-file"
            @change="loadImage($event)"
            accept="image/jpeg, image/png, image/jpg"
            style="display: none"
         />
         <span class="upload-image-label-text">
            Haz clic aquí<br> 
            <small>para seleccionar una imagen</small>
         </span>
      </label>
      <div 
         class="upload-image-preview" 
         v-if="show_preview_img"
      >
         <button 
            @click="deletePreview()"
            class="upload-image-preview-remove"   
         >
            <font-awesome-icon icon="times"/>
         </button>
         <img 
            class="upload-image-preview-img"
            :src="image.base64"/>
      </div>

      <!--MODAL-->
      <div class="upload-img-modal" v-if="open_croppie">
         <div class="upload-img-modal-container">
            <div class="upload-img-modal-container-close" @click="cancel()">
               <font-awesome-icon icon="times"></font-awesome-icon>
            </div>
            <div class="upload-img-modal-container-image">
               <vue-croppie
                  ref="croppieRef"
                  customClass="croppie"
                  :boundary="boundary"
                  :viewport="viewport"
                  :enableResize="false"
               >
               </vue-croppie>
            </div>
            <div class="upload-img-modal-container-buttons">
               <button
                  class="btn cancel"
                  @click="cancel()"
                  @keypress.esc="cancel()"
               >
                  Cancelar
               </button>
               <button 
                  class="btn save" 
                  @click="crop()">
                  Guardar
               </button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   props:{
      boundary_type: String,
      viewport: {
         type: Object,
         default() { return { width: 480, height: 270 } },
      },
   },

   data() {
      return {
         image: {
            width: "",
            height: "",
            base64: "",
            src: "",
            format: "",
         },
         image_file: null,
         open_croppie: false,
         show_preview_img: false,
         boundary: this.viewport,
         img: require("../assets/img/twu_logo.svg"),
      };
   },

   mounted() {
      // setTimeout(() => {
      //    this.$refs.croppieRef.bind({
      //       url: "https://ayjoe.engrave.site/img/default.jpg",
      //    });
      // }, 200);
      document.addEventListener("keypress", this.handleKeyPress);
      console.log('boundary type',this.boundary_type);
      if(this.boundary_type != undefined && this.boundary_type != ''){
         if(this.boundary_type == 'profile'){
            this.boundary = {
               width:300,
               height:300
            }
            this.viewport = { width: 300, height: 300 }
         }
      }
   },
   methods: {
      // Funcion que carga la imagen
      loadImage(event) {
         var input = event.target;
         if (input.files && input.files[0]) {
            if (input.files[0].size < 20000000) {
               if (
                  input.files[0]["type"] === "image/jpeg" ||
                  input.files[0]["type"] === "image/png"
               ) {
                  const filename = input.files[0].name;
                  var reader = new FileReader();
                  reader.onload = (e) => {
                     let img = new Image();
                     img.onload = () => {
                        this.image = {
                           width:  img.width,
                           height: img.height,
                           base64: img.src,
                           name:   filename
                        };
                     };

                     img.src = e.target.result;
                     this.open_croppie = true;

                     setTimeout(() => {
                        this.$refs.croppieRef.bind({
                           url: e.target.result,
                        });
                     }, 300);
                  };
                  this.image_file = input.files[0];
                  reader.readAsDataURL(input.files[0]);
               } else {
                  return;
               }
            }
         }
      },
      // Funcion para 'cortar' y luego envia a componente padre
      crop() {
         let options;

         this.$refs.croppieRef.result(options, (output) => {
            var file_src = output.split(","); //base64 cortado
            var format_data_split = file_src[0].split("/"); //obteniendo formato
            var format_image = format_data_split[1].split(";"); //obteniendo formato
            this.image.base64 = output; //Base64 completa
            this.image.src = file_src[1]; //Base64 sin data:image/png;base64
            this.image.format = format_image[0]; //formato
            this.image.all = output; //allfile
            //this.voidCrop();
         });

         setTimeout(() => {
            this.show_preview_img = true;
            this.open_croppie = false;
            this.$emit("sendImg", this.image);
         }, 300);
      },
      // Misma funcion 'crop()' ejecutada al presionar boton 'enter'
      handleKeyPress(e) {
         var key_pressed = e || window.event;
         if (key_pressed.keyCode == "13" && key_pressed.key == "Enter") {
            this.crop();
         }
      },
      voidCrop() {
         // this.$refs.croppieRef.bind({
         //    url: "https://ayjoe.engrave.site/img/default.jpg",
         // });
      },
      deletePreview() {
         this.image = {
            width: "",
            height: "",
            base64: "",
            src: "",
            format: "",
         };
         this.show_preview_img = false;
      },
      // Cancelar subida de imagen y cerrar modal croppie
      cancel() {
         this.open_croppie = false;
         // this.$emit("cancelUpload");
      },
   },
   beforeDestroy() {
      document.removeEventListener("keypress", this.handleKeyPress);
   },
};
</script>